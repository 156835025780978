@media only screen and (max-width:1440px) {
    .services-sec {
        padding: 50px 0px !important;

        .service-box {
            padding: 10px 20px !important;
        }
    }

    .twoSideFullWidthSec .inner-box {
        padding: 50px 50px !important;
    }

    h2 {
        font-size: 34.9987px !important;
        line-height: normal !important;
    }
    .ourBrand-sec {
        padding: 50px 0px 50px 0px !important;
    }
    .paddingY-100, .ah-py-100 {
        padding-top: 50px !important;
        padding-bottom: 50px !important;
    }
    .launchYourBusiness-sec {
        padding: 50px 0 !important;
    }
    h1 {
        font-size: 45px !important;
    }
}

@media only screen and (max-width:1199px) {
    .ourBrand-sec .row.justify-content-between.align-items-center {
        display: block;
        width: 100%;
    }
    
    .ourBrand-sec .row.justify-content-between.align-items-center > div {
        width: 100%;
    }
    .padding-50-100 {
        padding: 50px !important;
    }
}

@media only screen and (max-width:1024px) {}

@media only screen and (max-width:991px) {
    nav.sticky button.navbar-toggler img {
        filter: invert(1);
    }
    nav.sticky .nav-link {
        margin: 0 0px !important;
        padding: 10px 10px !important;
        font-size: 16px !important;
        color: white !important;
    }
    .services-sec .service-box {
        padding: 20px 10px !important;
    }
    section#ourBrand-sec {
        .col-lg-6 {
            margin-bottom: 50px;
    
            .col-12.mt-4 {
                text-align: center;
            }
        }
    }
    .address-content {
        display: block!important ;
    }
    
    .address-content > div {
        display: block !important;
        width: 100%!important;
    }
    .for-services-details .row {
        display: block !important;

        .sticky {
            position: relative !important;
            top: unset !important;
            height: unset !important;
        }

        .non-sticky .inner-box {
            padding: 20px !important;
        }
    }
    .questions-list li {
        font-size: 18px;
        margin-bottom: 10px;
    }
}

@media only screen and (max-width:767px) {
    body *{
        text-align: center !important;
    }
    h1 {
        font-size: 26px !important;
        line-height: inherit !important;
    }
    h2 {
        font-size: 20.9987px !important;
    }
    p {
        font-size: 14px !important;
    }
    h3 {
        font-size: 18px !important;
    }
    h4 {
        font-size: 16px !important;
    }
    .body-2 {
        font-size: 15px !important;
    }
    .body-1.content {
        font-size: 14px !important;
        padding: 15px !important;
        text-align: left !important;
    }
    .button-text {
        font-size: 14px !important;
    }
    .title-icon{
        padding: 18px 20px 18px 20px !important;
    }
    .accordion-title{ 
        text-align: left !important;
    }
    nav.navbar .container-fluid {
        padding: 0 10px !important;
    }
    .subscribe-sec {
        min-height: 410px;
    }

    .copyright {
        display: block;
    }

    .copyright p {
        margin: 10px 0px 0px 0px;
    }

    ul.social-icon li a {
        margin: 0 10px !important;
    }

    .copyright p,
    .copyright a {
        display: inline-block;
    }

    .copyright p {
        margin: 0px 0px 20px 14px;
    }

    .copyright ul {
        display: block;
        margin-bottom: 20px;
    }

    .copyright ul li {
        display: block;
        margin-left: 0;
    }
    .ourBrand-sec .ourBrand-services figcaption *{
        text-align: left !important;
    }

    ul.social-icon li:first-child a {
        margin-left: 0 !important;
    }

    footer .row {
        padding: 30px 0;
    }
    .footer-widget-heading h3 {
        margin-top: 20px !important;
        margin-bottom: 20px !important;
    }
    .footer-widget-heading h3::before {
        bottom: 0px;
        width: 100%;
    }
    .for-services-details .questions-list {
            padding-left: 10px !important;
        li{
            padding-left: 0px !important;

            * {
                text-align: left !important;
            }
        }
    }
    .footer-widget-heading h3::before {
        bottom: -2px !important;
        height: 2px !important;
        width: 100% !important;
    }
    .padding-50-100 {
        padding: 30px !important;
    }
    .accelerateYourBusiness-sec .description p {
        margin-bottom: 20px;
    }
    footer .copyright-area .row {
        padding: 0px !important;
        a{
            display: block;
        }
    }
    .footer-social-icon a{
        margin: 0 10px !important;
    }
    .address-content .ourBrand-sec{
        margin-top: 0px !important;
        padding: 10px 0px !important;
    }
    .contact-us-page {
        ul.ah-list-items{
            justify-content: center !important;
        }

        form input {
            height: 45px !important;
        }
        form .form-group{
            text-align: left !important;
        }
    }
    .launchYourBusiness-sec ul.business-flow li figure{
        display: block !important;
        div{
            margin-bottom: 15px !important;
            
            &::before{
                display: none ;
            }
        }
        figcaption{
            margin-left: 0 !important;
        }
    }
    .ah-list-items .ah-list-item .ah-list-text {
        text-align: left !important;
    }
    .form-group.mb-0.mbl-center {
        text-align: center !important;
    }
}

@media only screen and (max-width:640px) {
    h2 {
        font-size: 32px;
        line-height: 40px;
    }

    form button {
        font-size: 16px;
        padding: 15px 22px;
        height: 50px;
        height: auto;
    }

    form input {
        height: 55px;
        padding-right: 126px;
    }

    .subscribe-sec {
        min-height: 335px;
    }
}

@media only screen and (max-width:480px) {}

