

// SWITCH STYLING
// ***********************************************************************************
.input-checkbox {
  width: 2px;
}
.form-check-input {
  background-color: transparent;
  border-color: #bdcee0 !important;
  box-shadow: none;
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23BDCEE0'/%3e%3c/svg%3e") !important;
  width: 17px;
}

.form-switch .form-check-input:checked {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2.5' fill='%23FFFFFF'/%3e%3c/svg%3e") !important;
  width: 17px;
  background-color: #b70a80; /* Change this to the desired color */
  border: none;
  box-shadow: none;
}

.form-check-input:focus,
.form-check-input:active {
  outline: none;
  box-shadow: none;
}
