* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* utility classes */
.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* search bar styles */
.search-bar {
  height: 55px;
  padding-left: 35px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 48px;
  overflow: hidden;
  border: 0.1px rgba(255, 255, 255, 0.1) solid;
  justify-content: flex-end;
  align-items: center;
  gap: 383px;
  display: inline-flex;
  width: auto;

  .input-field,
  .search-button {
    text-align: center;
    word-wrap: break-word;
  }

  .input-field {
    color: #bdcee0;
  }

  .search-button-container {
    width: 154px;
    align-self: stretch;
    padding: 16px 32px;
    background: #21409a;
    border-radius: 48px;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    gap: 10px;
    display: inline-flex;

    .search-button {
      color: white;
      
    }
  }
}

/* media queries */
@media only screen and (max-width: 800px) {
  .search-bar {
    gap: 300px;
  }
}

@media only screen and (max-width: 700px) {
  .search-bar {
    gap: 200px;
  }
}

@media only screen and (max-width: 600px) {
  .search-bar {
    gap: 100px;
  }
}

@media only screen and (max-width: 500px) {
  .search-bar {
    gap: 50px;
  }

  
}

@media only screen and (max-width: 400px) {
  .search-bar {
    gap: 20px;
  }
}
