// RADIO BUTTON STYLING
// **********************************************************************************
// input white circle

.custom-radio-input {
  opacity: 0;
  position: relative;
  left: 0;
  top: 0;
  margin: 0;
}
// input pink circle
.custom-radio-checkmark {
  position: absolute;
  width: 20px; /* Adjust as needed */
  height: 20px; /* Adjust as needed */
  border: none; /* Remove the default border */
  border-radius: 50%;
  margin-right: 10px; /* Adjust as needed for spacing */
  background-color: #fff; /* Default inner circle color */
  transition: background-color 0.3s; /* Smooth transition */
}

.custom-radio-input:checked + .custom-radio-checkmark {
  background-color: white; /* Make the inner circle transparent */
}

/* Additional styles for centering the inner circle */
.custom-radio-checkmark::before {
  content: '';
  display: block;
  width: 10px; /* Adjust as needed */
  height: 10px; /* Adjust as needed */
  background-color: #b70a80; /* Pink color */
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 20;
  opacity: 0; /* Initially hide the inner circle */
  transition: opacity 0.3s; /* Smooth transition */
}

.custom-radio-input:checked + .custom-radio-checkmark::before {
  opacity: 1; /* Display the inner circle when checked */
}
