/*  ================= Our Brand Section ================= */
.services-sec {
  position: relative;
  padding: 80px 0px;
  text-align: center;

  .description {
    position: relative;
    margin-bottom: 50px;
  }

  .service-box * {
    bottom: -10px;
  }

  .service-box:hover * {
    bottom: 0px;
    color: white !important;
  }

  .service-box {
    position: relative;
    padding: 30px 40px;
    border: 1px solid #1d1d1d;
    text-align: center;
    justify-items: center;
    border-radius: 20px 20px 20px 20px;
    transition: 0.3s;
    margin: 12px 5px;
    transform: translateY(10px);
    -webkit-transform: translateY(10px);
    transition: 0.3s cubic-bezier(.65, .05, .36, 1);
    -webkit-transition: 0.3s cubic-bezier(.65, .05, .36, 1);

    .icon {
      position: relative;
      margin-bottom: 10px;
          transition: 0.3s cubic-bezier(.65,.05,.36,1);
    -webkit-transition: 0.3s cubic-bezier(.65,.05,.36,1);
    }

    h3 {
      position: relative;
      margin-bottom: 20px;
      font-size: 24px;
      color: #1D1D1D;
      font-weight: bold;
      // transition: 0.2s;
          transition: 0.3s cubic-bezier(.65,.05,.36,1);
    -webkit-transition: 0.3s cubic-bezier(.65,.05,.36,1);
    transition-delay: 100ms;
    -webkit-transition-delay: 100ms;
    }

    p {
      position: relative;
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 20px;
      color: #575757;
      transition: 0.3s;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      /* number of lines to show */
      line-clamp: 2;
      -webkit-box-orient: vertical;
          transition: 0.3s cubic-bezier(.65,.05,.36,1);
    -webkit-transition: 0.3s cubic-bezier(.65,.05,.36,1);
    transition-delay: 200ms;
    -webkit-transition-delay: 200ms;
    }

    .d-link {
      // bottom: -10px;
      opacity: 0;
      visibility: hidden;
      transform: translateY(0);
      transition: 0.3s cubic-bezier(.65,.05,.36,1);
      -webkit-transition: 0.3s cubic-bezier(.65,.05,.36,1);
      transition-delay: 300ms;
      -webkit-transition-delay: 300ms;
    }

    &:hover {
      background-color: transparent;
      background-image: linear-gradient(0deg, #01a5ae 0%, #abdfcb 100%);

      h3 {
        color: white;
        margin-bottom: 15px;
        background-color: white;
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
        background-clip: text;
        background-image: linear-gradient(146deg, white 40%, white 75%, white 100%);
      }

      .icon {
        transform: translateY(-5px);
        -webkit-transform: translateY(-5px);

        img {
          filter: brightness(0) invert(1);
        }
      }

      .d-link {
        // bottom: 0;
        opacity: 1;
        visibility: visible;
        transform: translateY(-10px);
        -webkit-transform: translateY(-10px);
      }
    }
  }
}