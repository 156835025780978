/*  ================= Our Brand Section ================= */
.ourBrand-sec {
    position: relative;
    padding: 150px 0px 150px 0px;

    .ourBrandMedia{
        position: relative;
        text-align: center;
    }

    .ourBrand-services {
        position: relative;
        display: flex;
        width: 100%;
        align-items: flex-start;
        gap: 10px;
        margin-bottom: 20px;

        figcaption {
            h4 {
                font-size: 20px;
                font-weight: 700;
            }
            p{
                font-size: 14px;
                // text-align: justify;
            }
        }
    }
    p{
        line-height: 24px;
    }

    .row {
        .row {
            margin-top: 45px;
        }
    }
}

@media (max-width: 767px) {
    .twoSideFullWidthSec .inner-box {
      min-height: 350px !important;
      padding: 20px !important;
    }
  }