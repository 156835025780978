* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

// UNIVERSAL STYLING

.helper-text {
  align-self: stretch;
  color: var(--dark-041-e-2-d-25, #c0c7ca);
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 8px;
}
.helper-error-text {
  align-self: stretch;
  color: #f24073;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 8px;
}

//states
// .input-default {
//   border-radius: 16px;
//   border: 1px solid var(--dark-041-e-2-d-50, #818e96);
// }
.input-disabled {
  border-radius: 16px;
  border: 1px solid var(--dark-041-e-2-d-75, #435661);
}
.input-error {
  border-radius: 16px;
  border: 1px solid #f24073;
}

// TEXTFIELD STYLING
// ********************************************************************************
// whole container
.input-area {
  flex: 1 0 0;
  color: var(--dark-041-e-2-d-50, #818e96);

  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  background-color: transparent;
  border: none;
  outline: none;
}
// text container
.input-text {
  display: flex;
  width: 552px;
  padding: 16px;
  //   justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 16px;
  border: 1px solid var(--dark-041-e-2-d-50, #818e96);

  &.input-disabled {
    border-radius: 16px;
    border: 1px solid var(--dark-041-e-2-d-75, #435661);
  }
  &.input-error {
    border-radius: 16px;
    border: 1px solid #f24073;
  }
}

// MULTILINE TEXT AREA STYLING
// ********************************************************************************
// whole container
.input-textarea {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: 16px;
  border: 1px solid var(--White, #fff);
  background: var(--dark-041-e-2-d-100, #041e2d);
  width: 1128px;
  height: 210px;
  padding: 24px;
}
// text container
.input-area2 {
  align-self: stretch;
  color: var(--White, #fff);
  height: 100%;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  background-color: transparent;
  border: none;
  outline: none;
}

// if hovered or active
.input-default:hover {
  border-radius: 16px;
  border: 1px solid var(--White, #fff);
}
.input-default:active,
.input-default:focus {
  border-radius: 16px;
  border: 1px solid var(--White, #fff);
}

// SEARCH FIELD STYLING
// *********************************************************************************
// whole container
.input-search {
  display: flex;
  width: 1128px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 16px;
  border: 1px solid var(--dark-041-e-2-d-50, #818e96);
  background: var(--dark-041-e-2-d-100, #041e2d);
  padding: 16px;
}
// text container
.input-area3 {
  flex: 1 0 0;
  color: var(--dark-041-e-2-d-50, #818e96);

  /* Body/Large */
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  background-color: transparent;
  border: none;
  outline: none;
}
