.primary {
    background-color: #32c1c9;
    color: #FFFFFF;
    border-radius: 50px;
    padding: 10px 20px;
    font-size: 14px;

    &:hover {
        background-color: #000000;
        color: white;
    }
}

// .primary-outlined {}

// .secondary {}

.secondary-outlined {
    background-color: transparent;
    color: #FFFFFF;
    border: 2px;
    border-radius: 50px;
    padding: 10px 20px;
    font-size: 14px;

    &:hover {
        background-color: #32c1c9;
    }

    &:active {
        background-color: #780a55 !important;
    }
}

// .link {
//     background-color: transparent;
//     color: #FFFFFF;
//     border: 2px solid transparent;
//     border-radius: 50px;
//     font-size: 14px;

//     &:hover {
//         background-color: #32c1c9;
//     }

//     &:active {
//         background-color: #780a55 !important;
//     }
// }

// .link-outlined {
//     background-color: transparent;
//     color: #32c1c9;
//     border: 2px solid #32c1c9;
//     padding: 10px 20px;
//     font-size: 14px;

//     &:hover {
//         background-color: #32c1c9;
//         color: white;
//     }

//     &:active {
//         background-color: #00706b !important;
//     }
// }