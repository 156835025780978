// /*  ================= Our Brand Section ================= */
// .services-sec {
//     position: relative;
//     padding: 80px 0px;
//     text-align: center;

//     .description {
//       position: relative;
//       margin-bottom: 50px;
//     }

//     .service-box * {
//       bottom: -10px;
//     }

//     .service-box:hover * {
//       bottom: 0px;
//       color: white !important;
//     }

//     .service-box {
//       position: relative;
//       padding: 30px 40px;
//       border: 1px solid #1d1d1d;
//       text-align: center;
//       justify-items: center;
//       border-radius: 20px 20px 20px 20px;
//       transition: 0.3s;
//       margin: 12px 5px;

//       .icon {
//         position: relative;
//         margin-bottom: 10px;
//         transform: translateY(10px);
//         -webkit-transform: translateY(10px);
//         transition: 0.3s cubic-bezier(.65, .05, .36, 1);
//         -webkit-transition: 0.3s cubic-bezier(.65, .05, .36, 1);

//       }

//       h3 {
//         position: relative;
//         margin-bottom: 20px;
//         font-size: 24px;
//         color: #1D1D1D;
//         font-weight: bold;
//         transition: 0.2s;
//       }

//       p {
//         position: relative;
//         font-size: 16px;
//         font-weight: 400;
//         margin-bottom: 20px;
//         color: #575757;
//         transition: 0.3s;
//         overflow: hidden;
//    text-overflow: ellipsis;
//    display: -webkit-box;
//    -webkit-line-clamp: 2; /* number of lines to show */
//            line-clamp: 2;
//    -webkit-box-orient: vertical;
//       }

//       .d-link {
//         bottom: -10px;
//         opacity: 0;
//       }

//       &:hover {
//         background-color: transparent;
//         background-image: linear-gradient(0deg, #01a5ae 0%, #abdfcb 100%);

//         .icon {
//           transform: translateY(-5px);
//           -webkit-transform: translateY(-5px);

//           img {
//             filter: brightness(0) invert(1);
//           }
//         }

//         .d-link {
//           bottom: 0;
//           opacity: 1;
//         }
//       }
//     }
//   }

.for-services-details {
    position: relative;
    background-color: white;

    .row {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 0px;
        position: relative;
        z-index: 1;

        .sticky {
            width: 100%;
            position: -webkit-sticky ;
            position: sticky;
            top: 80px;
            height: 100vh;

            .inner-box{
                min-height: 100vh;
            }
        }

        .non-sticky {
            width: 100%;
            display: grid;
            grid-template-columns: auto;
            grid-row-gap: 40px;
        }

        .inner-box{
            padding: 50px 35px;
        }
    }
    .questions-list {
        position: relative;
        padding-left: 40px;
        li {
            font-size: 20px;
            font-weight: 500;
            color: #32c1c9;
            margin-bottom: 20px;
            padding-left: 20px;
    
            .questions-title {
                font-size: 20px;
                font-weight: 500;
                color: black;
                margin-bottom: 3px;
            }
            .questions-description {
                font-size: 16px;
                font-weight: 400;
                color: #575757;
            }
            ul.sub-ul{
                li{
                    strong{
                        display: block;
                        font-size: 16px;
                        font-weight: 400;
                        color: black;
                        margin-bottom: 3px;
                    }
                }
            }
        }
    }
}