.remove-icon {
    position: absolute;
    background-color: rgb(255, 97, 97);
    color: white;
    border: 0;
    outline: none;
    font-size: 22px;
    line-height: 1px;
    font-weight: bold;
    top: -10px;
    right: -5px;
    border-radius: 50px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    img{
        display: block;
        width: 20px;
        height: auto;
        filter: brightness(0) invert(1);
        // fill: white;
    }

    &:hover{
        background-color: red;
    }

}
.p-relative{
    position: relative;
}
form .form-group textarea {
    min-height: 120px;
    border-radius: 15px;
    resize: none;
}

.confirmation-modal {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgb(0 0 0 / 73%);
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    div{
        position: relative;
        width: 350px;
        height: auto;
        z-index: 1;
        display: block;
        background-color: white;
        text-align: center;
        padding: 20px;
        border-radius: 10px;

        p{
            font-size: 17px;
            font-weight: 500;
            color: black;
            margin-bottom: 15px;
        }

        button{
            position: relative;
            padding: 5px 10px;
            font-size: 16px;
            background-color: aqua;
            border-radius: 50px;
            border: 0px;
            outline: none;
            width: 80px;
            margin: 0 5px;
            color: black;
            transition: 0.3s;

            &:hover{
                color: white;
                background-color: black;
            }
        }
    }
}