.accelerateYourBusiness-sec{
    position: relative;
    padding: 130px 0 50px 0;
    min-height: 700px;
    display: flex;
    width: 100%;
    align-items: center;

    &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        inset: 0;
        z-index: 2;
        background-color: transparent;
        background-image: linear-gradient(270deg, #01a5ae 50%, #b7efd6 100%);
        opacity: 0.99;
        mix-blend-mode: multiply;
        transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
    }

    .description{
        position: relative;
        z-index: 3;
        color: white;

        .badge, h1, p{
            color: white;
        }
        h1{
            margin-bottom: 20px;
        }
        p{
            font-size: 18px;
            margin-bottom: 35px;
        }
        button{
            color: white;
            border-color: white;
        }
    }
    .background-video {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        z-index: 1;
    }
}
