.twoSideFullWidthSec {
    align-self: flex-start;
    position: sticky;
    // top: 80px;
    // border-style: solid;
    // border-width: 1px 0px 1px 0px;
    // border-color: #1d1d1d;
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;

    .inner-box {
        position: relative;
        width: 100%;
        min-height: 822px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 120px 120px 120px 120px;
    }

    .left-inner-box {
        // border-style: solid;
        // border-width: 0px 1px 0px 0px;
        // border-color: #1d1d1d;
        transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    }
}