.job-box {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background-color: transparent;
    background-image: linear-gradient(0deg, #01a5ae34 0%, #abdfcb2c 100%);
    background-color: #ffffff;
    /* Initial background color */
    transition: background-color 0.3s ease;
    /* Transition for background color change */
    transition: background-image 0.3s ease;
    border-radius: 5px;
    padding: 40px;
    gap: 20px;
    margin-bottom: 20px;
    transition: 0.3s;
    min-height: 136px;

    .short-description {
        text-align: left;

        h3 {
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 5px;
            transition: background-color 0.3s ease;
            transition: background-image 0.3s ease;
            background-image: linear-gradient(to right, black, #32c1c9);
            -webkit-background-clip: text;
            background-clip: text;
            color: transparent;
            transition: color 0.5s ease;
        }

        .info {
            font-size: 18px;
            color: black;
        }
    }

    .icon-container {
        display: inline-block;
        /* Hide overflowing content */
    }

    .icon {
        display: inline-block;
        color: black;
        margin: 0px;
        font-size: 22px;
    }

    .icon {
        transition: transform 0.3s ease;
    }

    &:hover {
        background-color: transparent;
        background-image: linear-gradient(0deg, #01a5ae6c 0%, #abdfcb65 100%);

        .short-description {

            h3 {
                color: #ffffff;
            }

        }

        .icon {
            animation: slideRight 0.5s forwards;
            color: #32c1c9;
            /* Apply the slideRight animation on hover */
        }
    }

    @keyframes slideRight {
        0% {
            transform: translateX(-100%);
            /* Start position: move icon outside the container to the left */
            opacity: 0;
            /* Start position: hide the icon */
        }

        50% {
            opacity: 1;
            /* Halfway: show the icon */
        }

        100% {
            transform: translateX(0);
            /* End position: move icon back to its original position */
            opacity: 1;
            /* End position: keep the icon visible */
        }
    }
}

.post-details-page {
    position: relative;

    p {
        padding-bottom: 40px;
        font-size: 16px;
        color: #575757;
        text-align: left;
        letter-spacing: 0.2px;
        line-height: 24px;
    }
    div{
        font-size: 16px;

        ul li{
            line-height: 1.8;

            &::marker{
                color: #32c1c9;
                display: inline-block;
                box-shadow: 0 0 0 0 #f6d3d5;
            }
        }
    }
    h4{
        margin-bottom: 30px;
    }

    .non-sticky {
        display: grid;
        grid-template-columns: auto;
        grid-row-gap: 20px;
    }

    .left {
        padding: 0px 35px 0px 0px;

        figure {
            position: relative;
            margin-bottom: 60px;

            img {
                border-radius: 10px 10px 10px 10px;
            }
        }

        .ah-list-items {
            display: block;

            .ah-list-item {
                display: block;
            }
        }
    }

    .right {
        padding: 0px 0px 0px 35px;
    }

    .queries-form {
        position: relative;
        border-radius: 10px 10px 10px 10px;
        padding: 50px 40px;

        .about-job-info {
            color: white;
            transition: color 0.3s;
            padding: 0;

            li {
                position: relative;
                list-style: none;
                font-size: 17px;
                margin-bottom: 10px;
                letter-spacing: 0.2px;

                span {
                    font-weight: 600;

                    span {
                        font-weight: 400;
                    }
                }
            }

            li:last-child {
                margin-bottom: 0px;
            }
        }

        form {
            .form-group {

                label {
                    color: white;
                }

                input,
                textarea {
                    border-color: #e3e3e3;
                    background-color: transparent;
                    color: white;
                    margin-bottom: 5px;
                    border-radius: 10px;
                }

                textarea {
                    min-height: unset;
                    height: 115px;
                    overflow: auto;
                    resize: none;
                }

                span {
                    background-color: transparent;
                    font-weight: 400;
                    font-size: 14px;
                    left: unset;
                    right: 10px;
                    bottom: 10px;
                    color: red;
                    font-weight: normal;
                    display: block;
                }

                button {
                    background-color: black;

                    div {
                        font-weight: 400;
                        font-size: 16px !important;
                        letter-spacing: 0;
                        line-height: unset;
                    }

                    &:hover {
                        background-color: white;
                        color: black;
                    }
                }
            }
        }
    }
}