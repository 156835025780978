// Variables

$color-primary: #041e2d;
$color-white: #ffffff;
$color-light-white: rgba(255, 255, 255, 0);
$font-family: "Plus Jakarta Sans", sans-serif;

// Blue section styles
.blue-section {
  display: flex;
  flex-direction: column;
  z-index: 1;
  position: relative;
  overflow: hidden;
  background-color: $color-primary;

  .section-text {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    text-align: center;
    background: linear-gradient(
      92deg,
      $color-white 9.82%,
      $color-light-white 160.12%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    flex-shrink: 0;
  }

  .blue-section-text-center{
    max-width: 93.6rem;
  }
  

  span {
    color: #ecb9dc;
  }

  // media query
  @media (max-width: 768px) {
    height: 500px;
  }
}
