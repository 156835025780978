.about-us-page {
    button.btn {
        display: none;
    }
    .ourBrand-sec{
        padding: 80px 0px 50px 0px;
    }
}
.sticky-box{
    position: -webkit-sticky; position: sticky; top: 0; z-index: 1020;    
}