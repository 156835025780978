.launchYourBusiness-sec {
    position: relative;
    color: black;
    padding: 100px 0;
    // background-color: transparent;
    // background-image: linear-gradient(270deg, #18d5bb 0%, #004845 80%);

    .col-md-5 {
      padding-right: 30px;
    }

    h2 {
      // color: black;
      margin-bottom: 50px;
    }

    // p {
    //   color: black;
    // }

    ul.business-flow {
      position: relative;
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        position: relative;

        figure {
          position: relative;
          display: flex;
          width: 100%;
          margin-bottom: 40px;

          div {
            position: relative;

            &::before {
              content: " ";
              position: absolute;
              width: 1px;
              height: 65px;
              border-right: 2px dashed black;
              top: 40px;
              left: 50%;
              transform: translateX(-50%);
            }
          }

          img {
            width: 28px;
            height: 28px;
            object-fit: contain;
            // filter: brightness(0) invert(1);
            // font-size: 28px;
          }

          figcaption {
            position: relative;
            margin-left: 30px;

            h4 {
              font-size: 20px;
              font-weight: 700;
              margin-bottom: 5px;
            }

            p {
              font-size: 16px;
              font-weight: 400;
            }
          }
        }
      }

      li:last-child {
        figure {
          margin-bottom: 0px;

          div::before {
            display: none;
          }
        }
      }
    }
  }