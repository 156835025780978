/* ================= General ================= */
html, body{
  scroll-behavior: smooth;
}
body {
  background: white;
  font-family: "Plus Jakarta Sans", sans-serif;
  color: black;
}

section {
  position: relative;
  display: block;
}


.ah-container {
  max-width: 1620px;
}

.ah-container-fluid {
  max-width: 1680px;
}
.ah-container-services{
  max-width: 1420px;
  padding: 0 30px;
}

.ah-container-services, .ah-container, .ah-container-fluid{
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}

/*  =================Subscribe section  =================*/
.subscribe-sec {
  min-height: 910px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px 0px 30px 30px;
  background: radial-gradient(122.71% 245.32% at 50% -145.32%, #9ACBFF 0%, #041E2D 85.46%, #041E2D 100%);
}

.subscribe-sec .row {
  justify-content: center;
  text-align: center;
}

.paddingY-100, .ah-py-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.ah-pt-100 {
  padding-top: 100px;
}
.ah-pb-100 {
  padding-bottom: 100px;
}

.ah-pb-50 {
  padding-bottom: 50px;
}

.ah-pt-50 {
  padding-top: 50px;
}

.ah-mt-50 {
  margin-top: 50px;
}

.ah-mb-50 {
  margin-bottom: 50px;
}

h1 {
  position: relative;
  font-size: 65px;
  line-height: 65px;
  font-weight: 700;
}

h2 {
  display: inline-block;
  font-size: 45.9987px;
  font-style: normal;
  font-weight: 700;
  line-height: 59.7983px;
  /* 114.286% */
  color: black;
  margin-bottom: 15px;
  background-color: #01d3b6;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  background-image: linear-gradient(146deg, #000000 40%, #00d7cd 75%, #026460 100%);

  // span {
  //   // color: #01d3b6;
  //   color: #1d1d1d;
  //   background-color: #01d3b6;
  //   background-image: linear-gradient(146deg, #00d7cd 19%, #026460);
  //   -webkit-text-fill-color: transparent;
  //   -webkit-background-clip: text;
  //   background-clip: text;
  // }
}

h2.text-white, h3.text-white, h4.text-white {
  color: white;
  background-color: white;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  background-image: linear-gradient(146deg, #ffffff 40%, #ffffff 75%, #ffffff 100%);

  // span {
  //   // color: #01d3b6;
  //   color: #1d1d1d;
  //   background-color: #01d3b6;
  //   background-image: linear-gradient(146deg, #00d7cd 19%, #026460);
  //   -webkit-text-fill-color: transparent;
  //   -webkit-background-clip: text;
  //   background-clip: text;
  // }
}

h2.text-black, h3.text-black, h4.text-black {
  color: #000000;
  background-color: #000000;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  background-image: linear-gradient(146deg, #000000 40%, #000000 75%, #000000 100%);

  // span {
  //   // color: #01d3b6;
  //   color: #1d1d1d;
  //   background-color: #01d3b6;
  //   background-image: linear-gradient(146deg, #00d7cd 19%, #026460);
  //   -webkit-text-fill-color: transparent;
  //   -webkit-background-clip: text;
  //   background-clip: text;
  // }
}

h3 {
  display: inline-block;
  font-family: "Plus Jakarta Sans", Sans-serif;
  font-size: 36px;
  line-height: 1em;
  font-weight: 700;
  line-height: inherit;
  color: black;
  margin-bottom: 15px;
  background-color: #01d3b6;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  background-image: linear-gradient(146deg, #000000 40%, #00d7cd 75%, #026460 100%);

  // span {
  //   color: #01d3b6;
  //   color: #1d1d1d;
  //   background-color: #01d3b6;
  //   background-image: linear-gradient(146deg, #01e0c3 19%, #32c1c9);
  //   -webkit-text-fill-color: transparent;
  //   -webkit-background-clip: text;
  //   background-clip: text;
  // }
}

h4{
  font-size: 24px;
  font-weight: 700;
  line-height: 1.3;
  text-transform: capitalize;
  margin-bottom: 20px;
}
.badge {
  font-size: 13px;
  color: #32c1c9;
  font-weight: 400;
  border: 1px solid;
  border-radius: 50px;
  padding: 10px 15px 10px 15px;
  line-height: 1;
  margin-bottom: 15px;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  justify-self: center;
  gap: 10px;

  @keyframes opacityAnimation {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  img {
    width: 14px;
    height: 14px;
    // background-color: blue;
    animation: opacityAnimation 2s infinite;
  }

  span {
    // margin-left: 5px;
    font-size: 13px;
    line-height: 100%;
  }
}
.badge.white {
  img {
      filter: brightness(0) invert(1);
  }
}

p {
  font-size: 16px;
  color: #575757;
}

.p-50 {
  padding: 50px;
}

.bg-theme {
  background-color: transparent;
  background-image: linear-gradient(90deg, #01a5ae 0%, #abdfcb 100%);
}

.bg-theme-light {
  background-color: rgb(227 248 247);
  // background-image: linear-gradient(90deg, #01a5ae 0%, #abdfcb 100%);  
}

/* Subscribe Form */
form {
  position: relative;
  display: block;

  .form-group {
    position: relative;
    margin-bottom: 18px;

    label {
      margin-top: 5px;
      font-size: 16px;
      margin-bottom: 5px;
      font-weight: 600;

      sup {
        color: red;
        font-size: 14px;
      }
    }

    input,
    textarea {
      position: relative;
      border: 1px solid rgba(29, 29, 29, 0.14);
      border-radius: 8px;
      width: 100%;
      min-height: 50px;
      padding: 10px 20px;
      font-size: 16px;
      font-weight: 400;
      // text-transform: capitalize;
      color: black;
      margin-bottom: 0px;

      &:focus-visible {
        outline: #32c1c9 auto 1px;
      }
    }

    textarea, ReactQuill {
      min-height: 220px;
      border-radius: 15px;
      resize: none;
    }

    input::placeholder,
    textarea::placeholder {
      color: #BDCEE0;
    }

    span.for-error {
      position: absolute;
      left: 4px;
      bottom: 0;
      display: inline-block;
      color: red;
      font-size: 14px;
      line-height: 1;
      background-color: white;
      padding: 0px 3px;
    }

    button {
      // color: #FFF;
      // font-family: "Plus Jakarta Sans", sans-serif;
      // font-size: 20px;
      // font-weight: 400;
      // line-height: 24px;
      // border-radius: 48px;
      // background: #21409A;
      // padding: 15px 32px;
      // border: 1px solid #21409a;
      // transition: 0.3s;
      // outline: none;
      // height: 64px;
      // position: absolute;
      // right: 0px;

      &:hover {
        background-color: #000000;
      }
    }
  }
}

.h-200 {
  height: 200px;
}

.bg-color-box {
  position: relative;
}

.bg-light-gray {
  background-color: #DCDCDC36;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}

.padding-70-100 {
  padding: 70px 100px 70px 100px;
}

.padding-50-100 {
  padding: 50px 100px 50px 100px;
}

.bg-cover {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.ah-list-items {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  margin: 0;
  padding: 0;
  gap: 10px;

  .ah-icon-list-item {
    display: flex;
    font-size: inherit;

    .link-text,
    a {
      text-decoration: none;
      transition: 0.4s;
    }

    .link-text:hover {
      color: black !important;
    }
  }
}

.bg-light {
  background-color: #F5F3EF;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}

.d-link {
  position: relative;
  font-size: 14px;
  text-decoration: none;
  color: black;
  font-weight: 500;
  opacity: 1;
  transition: 0.4s;

  i {
    padding-left: 1px;
    position: relative;
    left: 0px;
    transition: 0.3s;
  }

  &:hover {
    color: #01a5ae;

    i {
      left: 5px;
    }
  }
}
.btn {
  min-width: 150px;
}

.btn-dark {
  background-color: black;
  border: 2px solid white;
  color: white;
  border-radius: 28px;
  padding: 14px 24px;
  font-size: 14px;
  transition: 0.3s;

  &:hover {
    background-color: #01a5ae;
    border: 2px solid white;
    color: black;
  }
}

.btn-outline {
  background-color: transparent;
  border: 2px solid white;
  color: white;
  border-radius: 28px;
  padding: 14px 24px;
  font-size: 14px;
  transition: 0.3s;

  &:hover {
    background-color: black !important;
    color: white !important;
    border-color: white;
  }
}

.ah-list-items {
  list-style-type: none;
  margin-bottom: 30px;
  padding: 0;

  .ah-list-item {
    padding-bottom: calc(10px / 2);
    display: flex;
    font-size: inherit;
    align-items: center;

    .ah-list-text {
      position: relative;
      display: block;
      font-size: 16px;
      padding-left: 20px;

      &::before {
        position: absolute;
        content: "";
        width: 6px;
        height: 6px;
        display: inline-flex;
        background: #32c1c9;
        box-shadow: 0px 0px 5px 1px #32c1c9;
        -webkit-box-shadow: 0px 0px 5px 1px #32c1c9;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        margin-right: 0px;
        top: 10px;
        left: 0px;
      }
    }
  }
}
.sticky{
  // width: auto;
  position: sticky;
  top: 80px;
  height: 100vh;
}
.non-sticky{
  // width: auto;
  display: grid;
  grid-template-columns: auto;
  grid-row-gap: 40px;
}
.p-relative{
  position: relative;
}
.quill {background: white;}

.quill .ql-toolbar {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.quill .ql-container {border-bottom-right-radius: 15px;border-bottom-left-radius: 15px; min-height: 100px;}
.error-message{
  color: red;
}