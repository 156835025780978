.innerPageBanner-sec {
    position: relative;
    overflow: hidden;
    background-color: black;

    .background-video {
        position: absolute;
        top: 50%;
        left: 50%;
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
        z-index: 0;
        transform: translate(-50%, -50%);
    }

    .ah-container {
        position: relative;
        z-index: 1; // Ensure text appears above the video
    }
}


.innerPageBanner-sec {
    position: relative;
    text-align: center;
    min-height: 410px;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding-top: 80px;
    background-position: top center;

    // &::after{
        // content: "";
        // position: absolute;
        // width: 100%;
        // height: 100%;
        // inset: 0;
        // z-index: 0;
        // background-color: transparent;
        // background-image: linear-gradient(270deg, #00c7d1 50%, #8dc5af 100%);
        // opacity: 0.99;
        // mix-blend-mode: multiply;
        // transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
        /* background: linear-gradient(45deg, #6e55a6, #e1a8c5); */
        // background: linear-gradient(90deg, rgb(94 93 93 / 50%) 0%, rgb(43 154 235 / 50%) 30%, rgb(237 194 215) 100%);
        // background-color: rgb(32 32 32 / 60%);
        // mix-blend-mode: multiply;
    // }

    .description {
        position: relative;
        z-index: 1;

        h1{
            margin-bottom: 10px;
            color: white;
            text-transform: uppercase;
            font-size: 50px;
        }
    }

    .breadcrumb{
        position: relative;
        text-align: center;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        gap: 8px;
        
        .d-link, .current{
            position: relative;
            display: inline-block;
            font-size: 16px;
            font-weight: 500;
            text-decoration: none;
            color: white;
        }
        .d-link{
            color: white;
        }
        .breadcrumb-default-delimiter {
            position: relative;
            display: inline-block;

            &::before {
                content: "";
                background-color: white;
                display: inline-block;
                height: 14px;
                opacity: 1;
                position: relative;
                top: -1px;
                vertical-align: middle;
                width: 1px;
                -webkit-transform: rotate(20deg);
                transform: rotate(20deg);
            }
        }
    }
}


