/* Extra small devices (portrait phones, less than 576px) */
/* No media query since this is the default in Bootstrap */
body {
  overflow-x: hidden;
  overflow-y: auto;
}

.landing-page-wrap {
  .innerPageBanner-sec{
    // display: none;
  }
}

section{
  background: white;
}