ul {
  margin: 0;
  padding: 0;
}

.footer-section {
  background: black;
  position: relative;
  padding-top: 20px;
}

.footer-cta {
  border-bottom: 1px solid #373636;
}

.single-cta i {
  color: #32c1c9;
  font-size: 30px;
  float: left;
  margin-top: 8px;
}

.cta-text {
  padding-left: 15px;
  display: inline-block;
}

.cta-text h4 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 2px;
}

.cta-text span {
  color: #757575;
  font-size: 15px;
}

.footer-content {
  position: relative;
  z-index: 2;
}

.footer-pattern img {
  position: absolute;
  top: 0;
  left: 0;
  height: 330px;
  background-size: cover;
  background-position: 100% 100%;
}

.footer-logo {
  margin-bottom: 30px;
}

.footer-logo img {
  max-width: 200px;
}

.footer-text p {
  margin-bottom: 14px;
  font-size: 14px;
  color: #e1e1e1;

  line-height: 28px;
}

.footer-social-icon span {
  color: #fff;
  display: block;
  font-size: 18px;
  font-family: "Plus Jakarta Sans", Sans-serif;
  margin-bottom: 20px;
}

.footer-social-icon a {
  color: #fff;
  font-size: 16px;
  margin-right: 15px;
  text-decoration: none;
}

.footer-social-icon i {
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 38px;
  border-radius: 50%;
  border: 1px solid;
  transform: 0.3s;

  &:hover{
    transform: scale(1.1);
    background-color: #07bbbb;
  }
}

// .facebook-bg {
//   background: #3B5998;
// }

// .twitter-bg {
//   background: #55ACEE;
// }

// .google-bg {
//   background: #DD4B39;
// }

.footer-widget-heading h3 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-top: 80px; 
  margin-bottom: 40px;
  position: relative;
}

.footer-widget-heading h3::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -15px;
  height: 2px;
  width: 50px;
  background: #32c1c9;
}

.footer-widget ul li {
  position: relative;
  display: block;
  // float: left;
  // width: 100%;
  margin-bottom: 12px;
}

.footer-widget ul li a:hover {
  color: #32c1c9;
}

.footer-widget ul li a {
  color: #e1e1e1;
  text-transform: capitalize;
  font-size: 14px;
  text-decoration: none;
}

.subscribe-form {
  position: relative;
  overflow: hidden;
}

.subscribe-form input {
  width: 100%;
  padding: 14px 28px;
  background: #e1e1e1;
  border: 1px solid #2E2E2E;
  color: black;
  font-size: 15px;
}

.subscribe-form button {
  position: absolute;
  background: #32c1c9;
  padding: 13px 20px;
  border: 1px solid #32c1c9;
  right: 0;
  top: 1px;
}

.subscribe-form button i {
  color: #fff;
  font-size: 22px;
  transform: rotate(-6deg);
}

.copyright-area {
  background: #202020;
  padding: 25px 0;
}

.copyright-text p {
  margin: 0;
  font-size: 14px;
  color: #e1e1e1;
}

.copyright-text p a {
  color: #32c1c9;
  text-decoration: none;
}

.footer-menu li {
  display: inline-block;
  margin-left: 14px;
}

.footer-menu li:hover a {
  color: #32c1c9;
}

.footer-menu li a {
  font-size: 16px;
  color: #e1e1e1;
  text-decoration: none;
}



// WebDesignDevelopment, 
// UIUXDesigning, 
// BusinessConsulting, 
// SearchEngineOptimization, 
// GraphicDesign, 
// SplitSlider1, 
// VideoAnimation, 
// DataScience,
// // seo, 
// // WebDesign, 
// // UiUxDesign, 
// seoService,
// WebDesignService,
// UiUxDesignService,
// GraphicDesignService,
// DataScienceService,
// VideoImageService

