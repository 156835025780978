.custom-slide {
  .text-container {
    bottom: 20px;
    left: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  img {
    height: 830px;
    object-fit: cover;
    border-radius: 16px;
  }

  .h2 {
    font-family: Jomhuria;
    width: 100%;
    margin-bottom: 30px;
  }

  .h3 {
    margin-bottom: 24px;
    max-width: 55.2rem;
    
  }
  
  .initials {
    span {
      color: #818496;
    
    }
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .custom-slide {
    div {
      position: relative;
      bottom: 400px;
      left: 125px;
    }

    

    
  }
}
