.contact-us-page {
    position: relative;

    .badge,
    h2 {
        margin-bottom: 30px;
    }

    .contactUs-Sec {
        .col-md-4 .description img {
            width: 100% !important;
            height: 350px !important;
            object-fit: cover;
            overflow: hidden;
        }

        form .form-group textarea {
            border-radius: 8px;
        }

        .description {
            margin-bottom: 0px;
        }
    }
}

.contactUs-Sec {
    > .ah-container > .row {
        box-shadow: 0 0 20px 5px #e1e1e1;
    }
    .description {
        position: relative;
        margin-bottom: 50px;
    }

    .col-md-4 {

        .description>* {
            color: white;
        }

        img {
            margin-bottom: 30px;
        }
    }

    .full-height {
        height: 250px;
    }
}