/* ================= Navbar ================= */
nav.navbar {
  position: absolute;
  width: 100%;
  height: 80px;
  top: 0;
  right: 0;
  left: 0;
  padding: 0;
  /* From https: //css.glass */
  background-color: black !important;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  z-index: 10;
  box-shadow: 30px 40px 90px 0px #091e2d;
  backdrop-filter: blur(12px);
  transition: 0.3s;


  .container-fluid {
      padding: 0 50px;
  }

  .for-sticky-nav {
    display: none;
  }
  .for-stuck-nav {
    display: block;
  }

  button.btn.primary{

    &:hover{
      background-color: white;
      color: black;
      border: 1px solid;
    }
  }
}

nav.sticky {
  position: fixed !important;
  background-color: white !important;
  transition: 0.3s;

  .navbar-nav .nav-link.active,
  .navbar-nav .nav-link.show,
  .navbar-nav .nav-link:hover,
  .navbar-nav .nav-link:focus {
    color: #32c1c9;
  }

  .nav-link {
    color: #4e4e4e;
    text-align: center;
    padding: 0px;
    margin: 0 12px;
  }

  .for-sticky-nav {
    display: block;
  }
  .for-stuck-nav {
    display: none;
  }
}

.text-logo {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.2s, opacity 0.2s linear, transform 0.2s ease-in-out;
  transform: translateX(-10px);
  max-width: 100px;
}

.logo:hover+.text-logo {
  visibility: visible;
  opacity: 1;
  transform: translateX(0);
}

nav.navbar .container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

nav.navbar .collapse {
  justify-content: center;
}

.navbar-nav .nav-link.active,
.navbar-nav .nav-link.show,
.navbar-nav .nav-link:hover,
.navbar-nav .nav-link:focus {
  color: white;
}

.nav-link {
  color: #cccccc;
  text-align: center;
  padding: 0 !important;
  margin: 0 12px;
}

@media only screen and (max-width: 991px) {
  .accelerateYourBusiness-sec::before {
    background-image: linear-gradient(270deg, #00767d 50%, #416957 100%) !important;
  }

  nav.navbar {
    box-shadow: 0px 3px 20px 0px #091e2d61 !important;
    .container {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .signup {
    display: none;
  }

  div#navbarSupportedContent {
    position: absolute;
    width: 100%;
    height: auto;
    top: 80px;
    right: 0;
    left: 0;
    /* From https: //css.glass */
    border: 1px solid #e1e1e1;
    background-color: #000000;
  }

  .nav-link {

    border-bottom: 1px solid #4e6a76;
    margin: 0;
    padding: 10px 0 !important;
  }

  li.nav-item {
    text-align: center;
  }

  .nav-link:hover {
    background-color: #32c1c9;
  }
}
@media only screen and (max-width:767px) {
  .accelerateYourBusiness-sec {
    min-height: 550px !important;
  }
}